import { Button } from '@/components/Button';
import { BUTTON_SIZE } from '@/components/Button/Button';
import { FormInputError } from '@/components/FormInputError';
import { Input } from '@/components/Input';
import usePageHeight from '@/hooks/usePageHeight';
import { RecycleSmartOfferThirdPartyLoginButtons } from '@/lib/recycle-smart-offer/components/RecycleSmartOfferHeading';
import BrandedSignUpHeader from '@/lib/session/components/BrandedSignUpHeader';
import PrivacyPolicy from '@/lib/session/components/PrivacyPolicy';
import useSessionLoginOrSignUp from '@/lib/session/hooks/useSessionLoginOrSignUp';
import BrandSessionIpadImage from '@/public/images/session/brands/img_brans_session_ipad.png';
import { yupResolver } from '@hookform/resolvers/yup';
import { getCookie } from 'cookies-next';
import capitalize from 'lodash/capitalize';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

export interface SignUpFormData {
  email: string;
}

const schema = Yup.object({
  email: Yup.string().email('Enter a valid email').required('Enter your email'),
});

export default function LoginOrSignUpBrand() {
  const router = useRouter();
  const pageHeight = usePageHeight();
  const referrer = getCookie('_greener_r');

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SignUpFormData>({
    reValidateMode: 'onBlur',
    defaultValues: { email: '' },
    resolver: yupResolver(schema),
  });

  const { isLoading, onSubmit } = useSessionLoginOrSignUp(
    setError,
    handleSubmit,
  );

  const referrerLabel = capitalize(referrer);

  return (
    <div
      className="grid h-full grid-cols-1 lg:grid-cols-2"
      style={{ minHeight: pageHeight }}
    >
      <div className="flex h-full flex-col">
        <BrandedSignUpHeader referrer={referrer as string} />

        <div className="flex h-full flex-col items-center px-4 py-6 lg:justify-center lg:px-0 lg:py-0">
          <div className="flex max-w-[30rem] flex-col gap-y-8">
            <div className="flex flex-col gap-y-2.5">
              <h3>Start your free subscription</h3>
              <p className="p3">
                Enter your email to create an account or log in.
              </p>
            </div>

            <div className="flex flex-col gap-y-4">
              <Input
                {...register('email')}
                errors={errors.email}
                type="email"
                placeholder="Enter your email"
                autoComplete="email"
                autoCapitalize="none"
              />
              <input type="hidden" autoComplete="current-password" disabled />
              {router.query.error ? (
                <FormInputError
                  errors={{
                    type: 'auth',
                    message: router.query.error as string,
                  }}
                />
              ) : null}

              <Button
                type="submit"
                title="Continue"
                fullWidth={true}
                size={BUTTON_SIZE.XLARGE}
                disabled={isLoading}
                loading={isLoading}
                onClick={onSubmit}
              />
            </div>

            <RecycleSmartOfferThirdPartyLoginButtons />
            <PrivacyPolicy />
          </div>
        </div>
      </div>

      <div className="flex hidden w-full flex-col items-center justify-center bg-signUpReferrerGradient px-[8.5rem] lg:flex">
        <div className="relative h-[33.5rem] w-full">
          <Image
            alt="Greener ipad image"
            src={BrandSessionIpadImage}
            layout="fill"
            objectFit="contain"
          />
        </div>

        <div className="flex items-start gap-x-8">
          <div className="flex min-w-[12.75rem] flex-col items-center text-gray-700">
            <h6>Regular price</h6>
            <h1>
              <span className="line-through">$228</span>
              <span className="h3 no-underline">/year</span>
            </h1>
          </div>

          <div className="flex min-w-[12.75rem] flex-col items-center text-green-500">
            <h6>{referrerLabel} customers</h6>
            <h1>FREE</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

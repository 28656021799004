import Spinner from '../Icons/Spinner';

export default function IndeterminateLoadingSpinner({
  loading,
}: {
  loading?: boolean;
}) {
  if (!loading) return null;
  return (
    <div className="flex justify-center py-12 md:py-20">
      <Spinner className="h-16 w-16 text-green-500" />
    </div>
  );
}

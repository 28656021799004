import { Button } from '@/components/Button';
import { BUTTON_SIZE } from '@/components/Button/Button';
import { FormInputError } from '@/components/FormInputError';
import { Input } from '@/components/Input';
import OrSeparator from '@/components/Separator/OrSeparator';
import {
  AppleButton,
  getEnabledButtons,
  GoogleButton,
  SocialLoginButton,
  XeroButton,
} from '@/components/ThirdPartyLoginButtons/ThirdPartyLoginButtons';
import Wrapper from '@/containers/Wrapper';
import { segmentEventName, sendAnalyticsEvent } from '@/helpers/analytics';
import { useContainerDimensions } from '@/hooks/useContainerDimensions';
import { useValidateEmailMutation } from '@/lib/auth/api';
import { handleFormSubmitException } from '@/lib/forms/helper/validation';
import PrivacyPolicy from '@/lib/session/components/PrivacyPolicy';
import MoneyIcon from '@/public/icons/recycle-smart-offer/ic_money.svg';
import RecycleIcon from '@/public/icons/recycle-smart-offer/ic_recycle.svg';
import WorldIcon from '@/public/icons/recycle-smart-offer/ic_world.svg';
import RecycleSmartHeaderImage from '@/public/images/recycle-smart-offer/img_reyclesmart_header.png';
import { yupResolver } from '@hookform/resolvers/yup';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { StaticImport } from 'next/dist/shared/lib/get-img-props';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

const headingPoints = [
  {
    icon: MoneyIcon,
    label: 'Recycle your soft plastics, e-waste, textiles and more.',
  },
  {
    icon: RecycleIcon,
    label: 'Spend less on general waste pick up and reduce landfill.',
  },
  {
    icon: WorldIcon,
    label: 'An easy way to accelerate your sustainability journey.',
  },
];

function RecycleSmartOfferHeadingItem({
  icon,
  label,
}: {
  icon: StaticImport;
  label: string;
}) {
  return (
    <div className="flex items-center gap-x-4 py-[0.344rem]">
      <div className="relative h-9 w-9">
        <Image alt="Heading item icon" src={icon} />
      </div>
      <p className="p3 text-white">{label}</p>
    </div>
  );
}

interface FormData {
  email: string;
}

const schema = Yup.object({
  email: Yup.string().email('Enter a valid email').required('Enter your email'),
});

export function RecycleSmartOfferThirdPartyLoginButtons() {
  const services = getEnabledButtons();

  if (services.length === 0) return null;

  return (
    <div className="flex flex-col gap-y-6">
      <OrSeparator />
      <div className="flex flex-col items-center gap-y-3">
        {services.map(service => {
          switch (service) {
            case 'apple':
              return <AppleButton key={service} />;
            case 'google':
              return <GoogleButton key={service} />;
            case 'facebook':
              return (
                <SocialLoginButton
                  key={service}
                  socialName="Facebook"
                  socialImage="/icons/ic_facebook.png"
                  onClick={() => {
                    alert('Not Implemented yet');
                  }}
                />
              );
            case 'xero':
              return <XeroButton key={service} />;
            default:
              return null;
          }
        })}
      </div>
    </div>
  );
}

export function RecycleSmartOfferHeading() {
  const headerPointRef = useRef<HTMLDivElement>(null);

  const router = useRouter();
  const { height } = useContainerDimensions(headerPointRef);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    reValidateMode: 'onBlur',
    defaultValues: { email: '' },
    resolver: yupResolver(schema),
  });

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [validateEmail, { isLoading }] = useValidateEmailMutation();

  // const redirectUrl = useLoginRedirectUrl();
  const redirectUrl = '/organisation/offers/recyclesmart-power-pickup';

  const onSubmit = handleSubmit(async ({ email }) => {
    if (isLoading) return;

    try {
      setIsSubmitting(true);
      sendAnalyticsEvent({
        event: segmentEventName.emailEntered,
        properties: {
          email,
          g4b_lead: true,
        },
      });

      const result = await validateEmail({ email }).unwrap();

      const searchParams = new URLSearchParams({
        email: email,
        redirect: redirectUrl,
      });

      // What's shown to the browser, email hidden
      const displayParams = new URLSearchParams({
        redirect: redirectUrl,
      });

      if (result.magic) {
        // Magic link
        router.push(
          `/session/token?${searchParams.toString()}`,
          `/session/token?${displayParams.toString()}`,
        );
      } else {
        // Password login
        router.push(
          `/session/login?${searchParams.toString()}`,
          `/session/login?${displayParams.toString()}`,
        );
      }
    } catch (e) {
      setIsSubmitting(false);
      const error = e as FetchBaseQueryError | SerializedError;
      if ('data' in error && error.status === 422) {
        // User does not exists, lets move him to sign up.
        const searchParams = new URLSearchParams({
          email: email,
          redirect: redirectUrl,
          create_organisation: (e as any).data.create_organisation ?? true,
        });

        // What's shown to the browser, email hidden
        const displayParams = new URLSearchParams({
          redirect: redirectUrl,
          create_organisation: (e as any).data.create_organisation ?? true,
        });

        router.push(
          `/session/signup?${searchParams.toString()}`,
          `/session/signup?${displayParams.toString()}`,
        );
        return;
      }
      handleFormSubmitException(e, setError);
    }
  });

  return (
    <div className="relative bg-[#003E41] py-9 lg:h-[39.375rem] lg:py-15">
      <Wrapper>
        <div className="flex flex-col items-center gap-x-[11.25rem] lg:flex-row lg:items-start">
          {/* Left content */}
          <div
            ref={headerPointRef}
            className="flex flex-col items-center gap-y-6 py-6 lg:items-start"
          >
            <h2 className="text-center text-white lg:text-start">
              Get 10% off{' '}
              <span className="text-green-300">
                hard-to-recycle waste pickup from your business
              </span>
            </h2>

            <div className="flex flex-col gap-y-3.5">
              {headingPoints.map(x => (
                <RecycleSmartOfferHeadingItem key={x.label} {...x} />
              ))}
            </div>
          </div>
          {/* Right content */}
          <div className="flex max-h-[31.875rem] max-w-[27.5rem] flex-col gap-y-6 rounded-[0.875rem] bg-white px-5 py-6 lg:p-9">
            <div className="flex flex-col gap-y-4">
              <h4 className="text-center">
                Join for free to unlock this offer
              </h4>
              <Input
                {...register('email')}
                errors={errors.email}
                type="email"
                placeholder="Enter your email"
                autoComplete="email"
                autoCapitalize="none"
              />
              <input type="hidden" autoComplete="current-password" disabled />
              {router.query.error ? (
                <FormInputError
                  errors={{
                    type: 'auth',
                    message: router.query.error as string,
                  }}
                />
              ) : null}

              <Button
                title="Continue"
                type="submit"
                fullWidth
                size={BUTTON_SIZE.LARGE}
                loading={isSubmitting}
                onClick={onSubmit}
              />
            </div>
            <RecycleSmartOfferThirdPartyLoginButtons />
            <PrivacyPolicy />
          </div>
        </div>

        {/* Recycle smart image */}
        <div
          className="absolute hidden h-[15.563rem] w-[30.75rem] lg:block"
          style={{ bottom: -(height - 256) }}
        >
          <Image
            alt="Recycle smart heder image"
            src={RecycleSmartHeaderImage}
          />
        </div>
      </Wrapper>
    </div>
  );
}

import { segmentEventName, sendAnalyticsEvent } from '@/helpers/analytics';
import { useValidateEmailMutation } from '@/lib/auth/api';
import { handleFormSubmitException } from '@/lib/forms/helper/validation';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { UseFormHandleSubmit, UseFormSetError } from 'react-hook-form';
import { SignUpFormData } from '../components/LoginOrSignUpBrand';
import useLoginRedirectUrl from './useLoginRedirectUrl';

export default function useSessionLoginOrSignUp(
  setError: UseFormSetError<SignUpFormData>,
  handleSubmit: UseFormHandleSubmit<SignUpFormData>,
) {
  const router = useRouter();
  const redirectUrl = useLoginRedirectUrl();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [validateEmail, { isLoading }] = useValidateEmailMutation();

  const onSubmit = handleSubmit(async ({ email }) => {
    if (isLoading) return;

    try {
      setIsSubmitting(true);

      sendAnalyticsEvent({
        event: segmentEventName.emailEntered,
        properties: {
          email,
          g4b_lead: true,
        },
      });

      const result = await validateEmail({ email }).unwrap();

      const searchParams = new URLSearchParams({
        email: email,
        redirect: redirectUrl,
      });

      // What's shown to the browser, email hidden
      const displayParams = new URLSearchParams({
        redirect: redirectUrl,
      });

      if (result.magic) {
        // Magic link
        router.push(
          `/session/token?${searchParams.toString()}`,
          `/session/token?${displayParams.toString()}`,
        );
      } else {
        // Password login
        router.push(
          `/session/login?${searchParams.toString()}`,
          `/session/login?${displayParams.toString()}`,
        );
      }
    } catch (e) {
      setIsSubmitting(false);
      const error = e as FetchBaseQueryError | SerializedError;
      if ('data' in error && error.status === 422) {
        // User does not exists, lets move him to sign up.
        const searchParams = new URLSearchParams({
          email: email,
          redirect: redirectUrl,
          create_organisation: (e as any).data.create_organisation ?? true,
        });

        // What's shown to the browser, email hidden
        const displayParams = new URLSearchParams({
          redirect: redirectUrl,
          create_organisation: (e as any).data.create_organisation ?? true,
        });

        router.push(
          `/session/signup?${searchParams.toString()}`,
          `/session/signup?${displayParams.toString()}`,
        );
        return;
      }

      handleFormSubmitException(e, setError);
    }
  });

  return { isLoading: isLoading || isSubmitting, onSubmit };
}

import Wrapper from '@/containers/Wrapper';
import OriginLogo from '@/public/icons/session/brands/origin_logo.png';
import TyroLogo from '@/public/icons/session/brands/tyro_logo.png';
import Logo from '@/public/logo.svg';
import Image from 'next/legacy/image';

export default function BrandedSignUpHeader({
  referrer,
}: {
  referrer: string;
}) {
  return (
    <div className="h-16 w-full bg-white md:h-18">
      <Wrapper>
        <div className="flex h-full w-full items-center justify-between">
          <div className="flex items-center gap-x-6">
            <div className="hidden md:block">
              <Image
                layout="fixed"
                src={Logo}
                alt="Greener for Business"
                width={223}
                height={20}
              />
            </div>
            <div className="md:hidden">
              <Image
                layout="fixed"
                src={Logo}
                alt="Greener for Business"
                width={178}
                height={16}
              />
            </div>

            <div className="h-8 w-[0.063rem] bg-gray-300" />

            {referrer === 'origin' ? (
              <>
                <div className="hidden md:block">
                  <Image
                    src={OriginLogo}
                    alt="Origin logo"
                    width={110}
                    height={34}
                    className="object-contain"
                  />
                </div>
                <div className="md:hidden">
                  <Image
                    src={OriginLogo}
                    alt="Origin logo"
                    width={70}
                    height={21}
                    className="object-cohtain"
                  />
                </div>
              </>
            ) : null}
            {referrer === 'tyro' ? (
              <>
                <div className="hidden md:block">
                  <Image
                    src={TyroLogo}
                    alt="Tyro logo"
                    width={71}
                    height={34}
                    className="object-contain"
                  />
                </div>
                <div className="md:hidden">
                  <Image
                    src={TyroLogo}
                    alt="Tyro logo"
                    width={70}
                    height={21}
                    className="object-contain"
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </Wrapper>
    </div>
  );
}

import { ReactNode } from 'react';
import { Footer } from '../Footer';
import { Header } from '../Header';

export default function SessionLayout({
  children,
  hideHeader,
  hideFooter,
}: {
  children: ReactNode;
  hideHeader?: boolean;
  hideFooter?: boolean;
}) {
  return (
    <div className="flex min-h-screen flex-col justify-stretch">
      {!hideHeader ? <Header minimalHeader notLoggedIn /> : null}
      {/* Scrolling area */}
      <div className="flex flex-grow flex-col">
        <main className="flex-grow">{children}</main>

        {!hideFooter ? <Footer minimalFooter /> : null}
      </div>
    </div>
  );
}

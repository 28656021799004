import moment from 'moment';
import { useRouter } from 'next/router';
import React from 'react';

export function getSavedLoginAppInfo() {
  const device = localStorage.getItem('device');
  const client = localStorage.getItem('client');
  const app_info = device && client ? { device, client } : undefined;
  return app_info;
}

export default function useSaveLoginRedirectUrl() {
  const [hasSavedRedirectUrl, setHasSavedRedirectUrl] =
    React.useState<boolean>(false);
  const router = useRouter();

  // Save the redirect from query string to local storage
  // this is so that we can access it if the user does a third party login
  // because its hard to make the redirect url flow through some services (e.g. apple)
  // because they want 100% complete redirect urls white-listed which is annoying.
  React.useEffect(() => {
    // Wait for the router to be ready
    if (router.isReady) {
      // Save it to local storage
      if (router.query.redirect as string) {
        const redirectWrittenAt = localStorage.getItem('redirect_written_at');
        if (redirectWrittenAt) {
          // Has redirect time
          const redirectWrittenAtValue = moment().diff(
            moment(redirectWrittenAt),
            'minutes',
          );

          // If its more than 5 mins, lets clear the redirect
          if (redirectWrittenAtValue > 5) {
            localStorage.removeItem('redirect');
            localStorage.removeItem('redirect_written_at');
          }
        }

        // Lets set new redirect if any
        localStorage.setItem('redirect', router.query.redirect as string);
        // Lets save written_at to check next time its read
        localStorage.setItem('redirect_written_at', moment().format());
      }

      if (router.query.device) {
        localStorage.setItem('device', router.query.device as string);
      }
      if (router.query.client) {
        localStorage.setItem('client', router.query.client as string);
      }

      setHasSavedRedirectUrl(true);
    }
  }, [
    router.isReady,
    router.query.client,
    router.query.device,
    router.query.redirect,
  ]);

  return hasSavedRedirectUrl;
}
